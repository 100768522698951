export const convertToArray = (intl, id, splitAt = "///") => {
  const rawArray = Object.keys(intl.messages).reduce((acc, item) => {
    if (item.includes(id)) {
      return [...acc, intl.messages[item]];
    }
    return acc;
  }, []);

  return rawArray.map(item => {
    const splitted = item.split(splitAt);
    return {
      heading: splitted[0],
      text: splitted[1],
    };
  });
};
